<template>
  <div class="index-page g-page rs-page">
    <!-- <el-button @click="open()">open</el-button>
    <el-button @click="opensuccess()">opensuccess</el-button> -->
    <div class="rs-center">
      <m-page-title title="Forgot your password?" describe="" />
      <el-form
        ref="resetForm"
        :model="reset"
        :rules="resetRules"
        label-position="top"
        class="reset-box"
        @keyup.enter.native="resetForm()"
        @submit.native.prevent
      >
        <div class="reset-box-top">
          <div v-if="!forgetstu">
            <el-form-item label="Email" prop="email">
              <div class="email">
                <el-input v-model="reset.email"></el-input>
                <el-button
                  type="primary"
                  class="verify-email-btn"
                  :loading="verifyEmailBtn.loading"
                  :disabled="Boolean(verifyEmailBtn.status)"
                  @click="verifyEmail"
                >
                  {{ verifyEmailBtn.label }}
                </el-button>
              </div>
            </el-form-item>
            <el-form-item label="Verification Code" prop="code">
              <el-input
                v-model="reset.code"
                placeholder=""
                class="form-input"
              ></el-input>
            </el-form-item>
          </div>
          <div v-else>
            <el-form-item label="New Password" prop="password">
              <el-input
                v-model="reset.password"
                type="password"
                placeholder=""
              ></el-input>
            </el-form-item>
            <el-form-item label="Confirm Password" prop="confirmPassword">
              <el-input
                v-model="reset.confirmPassword"
                type="password"
                placeholder=""
              ></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="line flex-row">
          <m-back />
          <el-button
            type="primary"
            :loading="reset.loading"
            class="reset-save"
            @click="resetForm()"
            >{{ forgetstu ? "Save" : "Next" }}</el-button
          >
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Please enter the password!"));
      } else {
        if (this.reset.confirmPassword !== "") {
          this.$refs.resetForm.validateField("confirmPassword");
        }
        callback();
      }
    };
    const validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Please enter the password again!"));
      } else if (value !== this.reset.password) {
        callback(new Error("The two passwords are inconsistent!"));
      } else {
        callback();
      }
    };
    return {
      forget: {
        email: "",
      },
      forgetstu: false,
      reset: {
        code: "",
        email: "",
        password: "",
        confirmPassword: "",
        loading: false,
      },
      verifyEmailBtn: {
        loading: false,
        label: "Verify Email",
        status: 0, // 倒计时 为0是可操作
      },
      resetRules: {
        email: [
          {
            required: true,
            message: "please enter your email",
            trigger: "blur",
          },
          {
            type: "email",
            message: "This email has not been registered yet",
            trigger: "blur",
          },
        ],
        code: [
          {
            required: true,
            message: "Please enter the Verification Code!",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "Please enter the password!",
            trigger: "blur",
          },
          {
            min: 8,
            message: "The minimum length is 8",
            trigger: "blur",
          },
          { validator: validatePass, trigger: "blur" },
        ],
        confirmPassword: [
          {
            required: true,
            message: "Please enter the password!",
            trigger: "blur",
          },
          {
            min: 8,
            message: "The minimum length is 8",
            trigger: "blur",
          },
          { validator: validatePass2, trigger: "blur" },
        ],
      },
      rules: {
        email: [
          {
            required: true,
            message: "This email has not been registered yet",
            trigger: "blur",
          },
          {
            type: "email",
            message: "This email has not been registered yet",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {},
  created() {
    //把键盘监听事件置空
    document.onkeydown = "";
  },
  mounted() {
    // bar
    this.navBarShow(false);
  },
  methods: {
    ...mapMutations("bar", ["navBarShow", "sideBarShow", "setSideBarStatus"]),
    goLogin() {
      this.$router.push("/login");
    },
    resetForm() {
      // 重置密码
      const that = this;
      that.reset.loading = true;
      that.$refs.resetForm.validate((valid) => {
        if (valid) {
          const parm = {};
          parm.email = that.reset.email;
          parm.code = that.reset.code;
          if (that.forgetstu) {
            parm.pwd = that.reset.confirmPassword;
            const options = [
              this.$api.resetPwd,
              parm,
              { headers: { domainName: this.$api.domain } },
            ];
            that.$axios.post(...options).then((res) => {
              that.reset.loading = false;
              if (res.code === "1000") {
                that.$notify.success(
                  "Password has been changed successfully, please log in using your new password."
                );
                this.$router.push("/login");
              }
            });
          } else {
            const options = [
              this.$api.getverify,
              parm,
              { headers: { domainName: this.$api.domain } },
            ];
            that.$axios.post(...options).then((res) => {
              that.reset.loading = false;
              if (res.code === "1000") {
                that.forgetstu = true;
              }
            });
          }
        } else {
          that.reset.loading = false;
          return false;
        }
      });
    },
    // 获取验证码
    async verifyEmail() {
      let msg = "msg";
      await this.$refs.resetForm.validateField("email", (err) => (msg = err));
      if (msg) return false;
      this.verifyEmailBtn.loading = true;
      const options = [
        this.$api.resetPwdEmail,
        { email: this.reset.email },
        { headers: { domainName: this.$api.domain } },
      ];
      this.$axios.post(...options).then((res) => {
        this.verifyEmailBtn.loading = false;
        if (res.code === "1000") {
          this.verifyEmailBtnCount();
          this.$notify.success("Verification code has been sent");
        }
      });
    },
    // 获取验证码倒计时
    verifyEmailBtnCount(index = 60) {
      this.verifyEmailBtn.label = index ? index + "s" : "Verify Email";
      this.verifyEmailBtn.status = index;
      index--;
      index >= 0 && setTimeout(() => this.verifyEmailBtnCount(index), 1000);
    },
  },
};
</script>

<style scoped lang="scss">
.g-page {
  padding: 45px 35px;
  background-color: #fbfbfb;
}
.index-page.rs-page {
  padding: 150px 130px 0 180px;
}
.email {
  display: flex;
  height: 100%;
  .verify-email-btn {
    margin-left: 5px;
    height: auto !important;
  }
}
.reset-box {
  width: 365px;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  .reset-box-top {
    flex: 1;
    ::v-deep .el-form-item__error {
      position: absolute;
      top: 0;
      line-height: 40px;
      width: 100%;
      left: 100%;
      padding-top: 0;
      padding-left: 20px;
    }
  }
  .reset-save {
    width: 100px;
    margin-left: 50px;
  }
}
.forget-pop-tittle {
  color: #1b3862;
  font-size: 21px;
  font-weight: 500;
  padding-bottom: 25px;
}
.forget-pop-txt {
  font-size: 13px;
  color: #9696a3;
  line-height: 15px;
  padding-bottom: 25px;
}
.forget-input-txt {
  color: #262629;
  font-size: 15px;
  padding-bottom: 5px;
  font-weight: normal;
}
.rs-page {
  height: 100%;
}
.rs-center {
  display: flex;
  flex-flow: column;
  height: 80%;
  .reset-box {
    flex: 1;
  }
}

@media screen and (max-width: 980px) {
  .reset-box {
    width: 100%;
    .reset-box-top {
      ::v-deep .el-form-item__error {
        position: absolute;
        line-height: 1;
        padding-top: 4px;
        top: 100%;
        left: 0;
        padding-left: 0;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .index-page.rs-page {
    padding: 20px 10px 0 10px;
  }
}
</style>
